import { BoxCards, Container, Content } from "./styles";
import { useGlobalState } from "contexts/global-state";
import CardService from "components/CardService";
import { MONEY_MASK } from "constants/masks";

import Header from "components/Header";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "constants/routes";
import LoadingFullPage from "components/LoadingFullPage";
import { useQuery } from "@tanstack/react-query";
import { INDICATOR_CODE, LEAD_DATA, QUERY_KEYS } from "constants/keys";
import { ApiService } from "services/api";
import { Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { decodedBase64 } from "utils/base64";


export default function FirstScreen() {
  const navigate = useNavigate();
  const { handleSelectService,  } = useGlobalState();
  const [queryParams] = useSearchParams();

  const [information, setInformation] = useState<LeadResponse | null>(null)

  const { data: servicesCategories, isLoading } = useQuery(
    QUERY_KEYS.SERVICES_CATEGORIES.LIST,
    async () => ApiService.ServicesCategories.getAll(),
  )

  const checkIfIndicatorIsActive = useCallback(async () => {

    if(!information?.indicator_code) {
      return
    }
    try {
      const responseIndicator = await ApiService.Clients.checkIfIndicatorIsActive(information.indicator_code.toString())
      if(responseIndicator) {
        sessionStorage.setItem(INDICATOR_CODE, information.indicator_code.toString())
      }
    } catch (error) {
      console.log(error)
    }
  }, [information])

  useEffect(() => {
    checkIfIndicatorIsActive()
  }, [checkIfIndicatorIsActive])

  const handleRedirect =  useCallback(async (selectedService: Services) => {
 
    handleSelectService(selectedService)
    let url = ROUTES.SERVICES.VIEW(selectedService.id.toString())
    navigate(url);
  }, [handleSelectService, navigate]);

  useEffect(() => {
    const data = queryParams.get('information')
    if(data) {
      try {
        const decodedData = JSON.parse(decodedBase64(data || '')) as LeadResponse
        sessionStorage.setItem(LEAD_DATA, JSON.stringify(decodedData))
        setInformation(decodedData)
      } catch (error) {
      }
    }
  }, [queryParams])

  if(isLoading) {
    return <LoadingFullPage/>
  }



  return (
    <Container>
      <Header />
      <Content>
        {servicesCategories?.length ? (
          <BoxCards >
          {servicesCategories?.map((serviceCategory) => {
            return (
              <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', gridColumn: '1/-1', width: '100%' }}>
              <Grid item xs={12}>
              <Typography
                variant="h4"
                sx={{ mt: 2, mb: 1, paddingLeft: "5px", fontWeight: "bold" }}
              >
                {serviceCategory.name}: 
              </Typography>
            </Grid>
              <Grid item xs={12} justifyContent="center" direction={{ xs: 'column', md: 'row' }} display={{ xs: 'grid', md: 'flex' }} flexWrap={{ md: "wrap" }} gap={3}>
              {serviceCategory.services?.map(service => (
                <CardService
                    key={service.id}
                    cover_image_url={service.cover_image_url}
                    title={service.name}
                    description={service.description}
                    price={MONEY_MASK(service.value)}
                    installments_value={!!service.interest_free_installments  ? MONEY_MASK(service.value / service.interest_free_installments) : undefined}
                    interest_free_installments={service.interest_free_installments}
                    onClick={() => handleRedirect(service)}
                  />
              ))}
              </Grid>
              </div>
            );
          })}
          </BoxCards>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p>Sem serviços no momento</p>
          </div>
        )}
      </Content>
    </Container>
  );
}
