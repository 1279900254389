import { yupResolver } from "@hookform/resolvers/yup";
import { Card, CardContent, Grid, TextField } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useGlobalState } from "contexts/global-state";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ApiService } from "services/api";
import { clientSchema } from "./form-validation";
import { getAddressByCEP } from "services/address";
import { useNavigate } from "react-router-dom";
import InputPassword from "components/InputPassword";
import { Button } from "components/Button";
import { Container } from "./styles";
import { LEAD_DATA, QUERY_KEYS, SELECTED_SERVICE } from "constants/keys";
import { DatePicker } from "components/DatePicker";
import Select from "components/Select";
import { SELECT_BRAZILIAN_STATES } from "constants/brazilian-states";
import { ROUTES } from "constants/routes";
import { cpfMask, phoneMask, zipCodeMask } from "utils/mask";
import LoadingFullPage from "components/LoadingFullPage";

export default function FormClient() {
  const navigate = useNavigate();

  const { openSuccessToast, openErrorToast, saveUser } = useGlobalState();
  const [stateValue, setStateValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm<ClientsApiRequest>({
    resolver: yupResolver(clientSchema),
  });

  const inputProps = useCallback(
    (fieldName: keyof ClientsApiRequest) => {
      return { InputLabelProps: { shrink: !!watch(fieldName) } };
    },
    [watch]
  );

  
  const { data: clientOrigins = [], isLoading: isLoadingClientOrigins } = useQuery(
    QUERY_KEYS.CLIENT_ORIGINS.GET_OPTIONS,
    async () => ApiService.ClientOrigins.getOptions(),
  )


  const handleCep = async ({ cep }: { cep: string}) => {
    if (cep.length === 9) {
      const address = await getAddressByCEP(cep);

      setValue("zip_code", cep, {
        shouldValidate: true
      });
      setValue("street", address?.street || "", {
        shouldValidate: true
      });
      setValue("city", address?.city || "", {
        shouldValidate: true
      });
      setValue("uf", address?.state || "", {
        shouldValidate: true
      });
      setValue("district", address?.neighborhood || "", {
        shouldValidate: true
      });
      setStateValue(address?.state || "")
    }
  };

  const handleSearchCpf = useCallback(async (cpf: string) => {
    const existCpf = await ApiService.Clients.checkIfCpfExist(cpf)
    if(!existCpf) return
    openSuccessToast({
      message: 'Já existe um login para esse cpf'
    })
    navigate(`${ROUTES.LOGIN}?section=login`)
  }, [navigate, openSuccessToast])

  const loginClient = useMutation({
    mutationFn: async (client: ClientsApiRequest) => {
      setIsLoading(true)
      const publicToken = await ApiService.PublicKey.get();

      const newClient = await ApiService.Clients.create(client, publicToken);
      saveUser(newClient)
    },
    onSuccess: (_data, client: ClientsApiRequest) => {
      openSuccessToast({
        message: `O cliente foi adicionado com sucesso!`,
      });

      const service = localStorage.getItem(SELECTED_SERVICE);
      if (service) {
        navigate(`/contracts/${JSON.parse(service).id}`);
        return;
      }
      setIsLoading(false)
      navigate("/");
    },
    onError: (error: ApiError) => {
      setIsLoading(false)
      openErrorToast({
        message: error.message || `Erro ao adicionar este cliente!`,
      });
    },
  });

  useEffect(() => {
    const storageLead = sessionStorage.getItem(LEAD_DATA)
    if(storageLead) {
      const lead = JSON.parse(storageLead)
      setValue('name', lead.name)
      setValue('email', lead.email)
      setValue('cell_phone', lead.phone)
    }
  }, [setValue])

  if(isLoading || isLoadingClientOrigins) {
    return <LoadingFullPage />
  }

  return (
    <form
      onSubmit={handleSubmit((clients: ClientsApiRequest) => {
        try {
          loginClient.mutate(clients);
        } catch (error) {}
      })}
    >
      <Container>
      <Card>
        <CardContent>
          <Grid
            container
            spacing={3}
            xs={12}
            sx={{ borderRadius: "0 0 0.5rem 0.5rem"  }}
            direction={{ xs: 'column', md: 'row' }}
          >
            <Grid item xs={12} md={3} style={{ margin: "16px 0 8px" }} >
              <Controller
                name="cpf"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextField
                      error={!!errors.cpf?.message}
                      helperText={
                        errors.cpf?.message
                      }
                      fullWidth
                      label="CPF"
                      id="cpf"
                      onChange={(e) => {
                        e.target.value = cpfMask(e.target.value)
                        onChange(e)
                        if(e.target.value.length === 14) {
                          handleSearchCpf(e.target.value)
                        }
                      }}
                      value={value}
                      {...inputProps("cpf")}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputPassword
                fullWidth
                label="Senha - Min. 6"
                id="password"
                type="password"
                {...register("password")}
                inputProps={{
                  pattern: "^.{6,}$",
                  ...inputProps("password"),
                }}
                error={!!errors.password}
                helperText={errors.password?.message}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                error={!!errors.name?.message}
                fullWidth
                helperText={errors.name?.message}
                label="Nome completo"
                id="name"
                margin="normal"
                {...inputProps("name")}
                {...register("name", {
                  minLength: {
                    value: 11,
                    message: 'Coloque seu nome completo'
                  }
                })}
              />
            </Grid>
            <Grid item xs={12} md={3} style={{ margin: "16px 0 8px" }}>
              <Controller
                name="birth_date"
                control={control}
                render={({ field: { value, onChange } }) => {
                  return (
                    <DatePicker
                    label="Data de nascimento"
                    disableFuture
                    value={value}
                    onChange={onChange}
                    format="DD/MM/YYYY"
                    fullWidth
                    error={errors?.birth_date?.message}
                    
              />  
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                error={!!errors.email?.message}
                helperText={errors.email?.message}
                label="E-mail"
                id="email"
                margin="normal"
                type="email"
                {...inputProps("email")}
                {...register("email")}
              />
            </Grid>
            <Grid item xs={12} md={3} style={{ margin: "16px 0 8px" }}>
              <Controller
                name="cell_phone"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextField
                      error={!!errors.cell_phone?.message}
                      helperText={
                        errors.cell_phone?.message
                      }
                      fullWidth
                      label="Celular"
                      id="cell_phone"
                      onChange={(e) => {
                        e.target.value = phoneMask(e.target.value)
                        onChange(e)
                      }}
                      value={value}
                      {...inputProps("cell_phone")}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={3} style={{ margin: "16px 0 8px" }}>
              <Controller
                name="phone"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextField
                      error={!!errors.phone?.message}
                      helperText={
                        errors.phone?.message
                      }
                      fullWidth
                      label="Telefone"
                      id="phone"
                      onChange={(e) => {
                        if(e.target.value?.length > 14) return
                        e.target.value = phoneMask(e.target.value)
                        onChange(e)
                      }}
                      value={value}
                      {...inputProps("phone")}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={3} style={{ margin: "16px 0 8px" }}>
              <Controller
                name="zip_code"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextField
                      error={!!errors.zip_code?.message}
                      helperText={
                        errors.zip_code?.message
                      }
                      fullWidth
                      label="CEP"
                      id="zip_code"
                      onBlur={async ()=> handleCep({ cep: value })}
                      onChange={(e) => {
                        e.target.value = zipCodeMask(e.target.value)
                        onChange(e)
                      }}
                      value={value}
                      {...inputProps("zip_code")}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                error={!!errors.district?.message}
                fullWidth
                helperText={errors.district?.message}
                label="Bairro"
                id="district"
                margin="normal"
                {...inputProps("district")}
                {...register("district")}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                error={!!errors.street?.message}
                fullWidth
                helperText={errors.street?.message}
                label="Endereço"
                id="street"
                margin="normal"
                {...inputProps("street")}
                {...register("street")}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                error={!!errors.number?.message}
                fullWidth
                helperText={errors.number?.message}
                label="Número"
                id="number"
                margin="normal"
                {...inputProps("number")}
                {...register("number")}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                error={!!errors.complement?.message}
                fullWidth
                helperText={errors.complement?.message}
                label="Complemento"
                id="complement"
                margin="normal"
                {...inputProps("complement")}
                {...register("complement")}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                error={!!errors.city?.message}
                fullWidth
                helperText={errors.city?.message}
                label="Cidade"
                id="city"
                margin="normal"
                {...inputProps("city")}
                {...register("city")}
              />
            </Grid>
            <Grid item xs={12} md={3}>
            <Controller
                            name="uf"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  error={!!errors.uf?.message}
                                  helperText={errors.uf?.message}
                                  fullWidth
                                  label="Estado"
                                  id="uf"
                                  options={SELECT_BRAZILIAN_STATES}
                                  onChange={(event) => {
                                    setStateValue(event.target.value as string)
                                    onChange(event)
                                  }}
                                  value={stateValue}
                                />
                              )
                            }}
                          />
            </Grid>
            <Grid item xs={12} md={3}>
            <Controller
                            name="client_origin_id"
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <Select
                                  error={!!errors.client_origin_id?.message}
                                  helperText={errors.client_origin_id?.message}
                                  fullWidth
                                  label="Origem"
                                  id="client_origin_id"
                                  options={clientOrigins}
                                  onChange={(event) => {
                                    onChange(event)
                                  }}
                                  value={value}
                                />
                              )
                            }}
                          />
            </Grid>
            {/* <Grid item xs={12} md={3}>
              <TextField
                error={!!errors.country?.message}
                fullWidth
                helperText={errors.country?.message}
                label="País"
                id="country"
                margin="normal"
                {...inputProps("country")}
                {...register("country")}
              />
            </Grid> */}
            <Grid item xs display="flex" alignItems="end" justifyContent="end">
            <Button
              type="submit"
              color="primary"
              variant="contained"
              fullWidth
              className="register-button"
            >
              Criar
            </Button>
            </Grid>
          </Grid>
          
        </CardContent>
      </Card>
      </Container>
    </form>
  );
}
