import styled, { createGlobalStyle, keyframes } from "styled-components";
import { WhatsAppColored as WhatsAppColoredIcon } from 'assets/WhatsAppColored'

export default createGlobalStyle`

  body {

    line-height: 1;
  }
  html {
    background-color: var(--gray-1);
  }
  hr {
    background: transparent;
    border: 0;
    clear: both;
    color: transparent;
    height: 1px;
    margin: 0;
    padding: 0;
  }
  mark {
    background-color: #ffffb3;
    font-style: italic
  }
  input, select {
    vertical-align: middle;
  }
  ol, ul {
    list-style: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  :root {
    --white: #FFFFFF;
    --primary-color: #FC741D;
    --secondary-color: #0095E8;
    --light-blue: #51C5E9;
    --dark-blue: #062C53;
    --midnight-blue: #3F5262;
    --gray-1: #EFF3F8;
    --gray-2: #7B838E;
    --gray-3: #4A4F56;
    --gray-4: #242424;
    --gray-5: #1A1A1A;

    --buttons-padding: 1rem 1.875rem;
  }

  html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, and, address, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, input, textarea, select {
    background: transparent;
    border: 0;
    font-size: 100%;
    font-family: 'Nunito', sans-serif !important;
    font-weight: 400;
    margin: 0;
    outline: 0;
    padding: 0;
    vertical-align: baseline;
  }
`;

export const WhatsAppChannel = styled.a`
  position: fixed;
  bottom: 12px;
  right: 12px;
  cursor: pointer;
`

const shaking = keyframes`
  0% { transform: translateY(0); }
  25% { transform: translateY(5px); }
  50% { transform: translateY(-5px); }
  75% { transform: translateY(5px); }
  100% { transform: translateY(0); }
`

export const WhatsAppColored = styled(WhatsAppColoredIcon)<{ count: string }>`
  animation: ${shaking} 0.8s infinite;
`
