export const CLIENT_SESSION_STORAGE_KEY = "client";
export const PUBLIC_TOKEN_SESSION_STORAGE_KEY = 'public-token'
export const JWT_EXPIRATION_DATE = 'client-token-validity'
export const PUBLIC_JWT_EXPIRATION_DATE = 'client-public-token-validity'
export const SELECTED_SERVICE = 'selected-service'
export const INDICATOR_CODE = 'indicator-code'
export const LEAD_DATA = 'lead-data'
export const QUERY_KEYS = {
  CONTRACTS: {
    LIST: ["contracts"],
    EDIT: (id: string) => ["contracts", id],
    VIEW: (id: string) => ["contracts", id],
  },

  SERVICES: {
    LIST: ["services"],
    EDIT: (id: string) => ["services", id],
    VIEW: (id: string) => ["services", id],
    NAMES: ["services-names"],
  },

  SERVICES_CATEGORIES: {
    LIST: ["services-categories"],
  },

  CLIENTS: {
    LIST: ["clients"],
    EDIT: (id: string) => ["clients", id],
    VIEW: (id: string) => ["clients", id],
  },

  CLIENT_ORIGINS: {
    GET_OPTIONS: ["clients-get-options"],
  },
};
